<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<div class="col-md-12 table-responsive">
						<table class="table table-sm table-striped table-dark">
							<tr>
								<th class="text-center">STOCK</th>
								<th class="text-center">JENIS</th>
								<th class="text-center">BUYER</th>
								<th class="text-center">ORDER</th>
								<th v-if="guard('harga_beli')" class="text-center">
									TOTAL<br />HARGA BELI
								</th>
								<th v-if="guard('harga_beli')" class="text-center">
									TOTAL<br />MAX PENJUALAN
								</th>
							</tr>
							<tr>
								<td class="text-center">{{ tbl1.stock.toLocaleString() }}</td>
								<td class="text-center">{{ tbl1.jenis.toLocaleString() }}</td>
								<td class="text-center">{{ tbl1.buyer.toLocaleString() }}</td>
								<td class="text-center">{{ tbl1.order.toLocaleString() }}</td>
								<td v-if="guard('harga_beli')" class="text-center">
									{{
										tbl1.estimasi
											? parseInt(tbl1.estimasi.hargaBeli).toLocaleString()
											: 0
									}}
								</td>
								<td v-if="guard('harga_beli')" class="text-center">
									{{
										tbl1.estimasi
											? parseInt(tbl1.estimasi.hargaJual).toLocaleString()
											: 0
									}}
								</td>
							</tr>
						</table>
					</div>
				</CRow>

				<!-- REKAP HARIAN by BULAN -->
				<div>
					<div class="row">
						<div class="col-md-12 text-center">
							<h3>
								REKAP HARIAN by BULAN <br />
								{{ bulan[frmBulanan.bulan] + " - " + frmBulanan.tahun }}
							</h3>
						</div>
					</div>
					<CRow>
						<CCol md="3">
							<div role="group" class="form-group">
								<select
									v-model="frmBulanan.tahun"
									id="asal"
									class="form-control"
								>
									<option :key="t" v-for="t in tahun" :data-key="t" :value="t">
										{{ t }}
									</option>
								</select>
							</div>
						</CCol>
						<CCol md="3">
							<div role="group" class="form-group">
								<select
									v-model="frmBulanan.bulan"
									id="asal"
									class="form-control"
								>
									<option
										:key="b"
										v-for="(b, i) in bulan"
										:data-key="b"
										:value="i"
									>
										{{ b }}
									</option>
								</select>
							</div>
						</CCol>

						<div class="col-md-12 table-responsive">
							<table class="table table-striped">
								<tr>
									<th class="text-center">Tanggal</th>
									<th class="text-right">Jumlah Order</th>
									<th class="text-right">Jumlah Item</th>
									<th v-if="guard('harga_beli')" class="text-right">
										Total Bayar
									</th>
									<th v-if="guard('harga_beli')" class="text-right">
										Total Harga Beli
									</th>
									<th class="text-right">Jumlah Cancel</th>
									<th class="text-right">Jumlah Return</th>
								</tr>

								<tr v-for="b in tblBulan" :key="b.tanggal">
									<td class="text-center">
										{{ b.tanggal }} {{ getDayName(b.tanggal) }}
									</td>
									<td class="text-right">
										{{ b.jumlahOrder.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahItem.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ b.totalBayar.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ b.totalHargaBeli.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahCancel.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahReturn.toLocaleString() }}
									</td>
								</tr>
								<tr>
									<td class="text-right">TOTAL</td>
									<td class="text-right">
										{{ totalBulanan.jumlahOrder.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalBulanan.jumlahItem.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ totalBulanan.totalBayar.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ totalBulanan.totalHargaBeli.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalBulanan.jumlahCancel.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalBulanan.jumlahReturn.toLocaleString() }}
									</td>
								</tr>
							</table>
						</div>
					</CRow>
				</div>

				<!-- REKAP BULANAN by TAHUN -->
				<div>
					<div class="row">
						<div class="col-md-12 text-center">
							<h3>
								REKAP BULANAN by TAHUN <br />
								{{ frmTahunan.tahun }}
							</h3>
						</div>
					</div>
					<CRow>
						<CCol md="3">
							<div role="group" class="form-group">
								<select
									v-model="frmTahunan.tahun"
									id="asal"
									class="form-control"
								>
									<option :key="t" v-for="t in tahun" :data-key="t" :value="t">
										{{ t }}
									</option>
								</select>
							</div>
						</CCol>
						<div class="col-md-12 table-responsive">
							<table class="table table-striped">
								<tr>
									<th class="text-center">Bulan</th>
									<th class="text-right">Jumlah Order</th>
									<th class="text-right">Jumlah Item</th>
									<th v-if="guard('harga_beli')" class="text-right">
										Total Bayar
									</th>
									<th v-if="guard('harga_beli')" class="text-right">
										Total Harga Beli
									</th>
									<th class="text-right">Jumlah Cancel</th>
									<th class="text-right">Jumlah Return</th>
								</tr>

								<tr v-for="b in tblTahun" :key="b.bulan">
									<td class="text-center">
										{{ bulan[b.bulan] }}
									</td>
									<td class="text-right">
										{{ b.jumlahOrder.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahItem.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ b.totalBayar.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ b.totalHargaBeli.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahCancel.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ b.jumlahReturn.toLocaleString() }}
									</td>
								</tr>
								<tr>
									<td class="text-right">TOTAL</td>
									<td class="text-right">
										{{ totalTahunan.jumlahOrder.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalTahunan.jumlahItem.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ totalTahunan.totalBayar.toLocaleString() }}
									</td>
									<td v-if="guard('harga_beli')" class="text-right">
										{{ totalTahunan.totalHargaBeli.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalTahunan.jumlahCancel.toLocaleString() }}
									</td>
									<td class="text-right">
										{{ totalTahunan.jumlahReturn.toLocaleString() }}
									</td>
								</tr>
							</table>
						</div>
					</CRow>
					<div>
						<BarChart
							v-if="chart.loaded"
							:chartdata="chart.data"
							:options="chart.option"
						/>
					</div>
				</div>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { debounce, bulan, tahun, getDayName, guard } from "@/plugins/utils";
import BarChart from "./mychart/Bar.vue";

export default {
	name: "Dashboard",
	components: {
		BarChart,
	},

	data() {
		return {
			guard,
			tbl1: {
				stock: 0,
				jenis: 0,
				buyer: 0,
				order: 0,
			},
			currentTime: new Date(),
			tblBulan: [],
			tahun: tahun,
			bulan: bulan,
			frmBulanan: {
				tahun: 2022,
				bulan: 1,
			},
			totalBulanan: {
				jumlahOrder: 0,
				jumlahItem: 0,
				totalBayar: 0,
				totalHargaBeli: 0,
				jumlahCancel: 0,
				jumlahReturn: 0,
			},
			tblTahun: [],
			frmTahunan: { tahun: 2022 },
			totalTahunan: {
				jumlahOrder: 0,
				jumlahItem: 0,
				totalBayar: 0,
				totalHargaBeli: 0,
				jumlahCancel: 0,
				jumlahReturn: 0,
			},
			getDayName,
			chart: {
				loaded: false,
				data: {
					labels: ["a", "b", "c", "d", "e", "f"],
					datasets: [
						{
							label: "Data One",
							backgroundColor: "#1a60ed",
							data: [1, 2, 3, 4, 5, 6],
						},
					],
				},
				option: {
					responsive: true,
					maintainAspectRatio: false,
					elements: {
						line: {
							tension: 0.18, // disables bezier curves
						},
					},
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
					},
				},
			},
		};
	},
	watch: {
		frmBulanan: {
			deep: true,
			handler(val) {
				this.getPerhari(val.tahun, val.bulan);
			},
		},
		frmTahunan: {
			deep: true,
			handler(val) {
				this.getPerbulan(val.tahun);
			},
		},
	},
	mounted() {
		this.getTbl1();
		this.frmBulanan = {
			tahun: this.currentTime.getFullYear(),
			bulan: this.currentTime.getMonth() + 1,
		};
		this.frmTahunan = {
			tahun: this.currentTime.getFullYear(),
		};
		//this.getPerhari(this.frmBulanan.tahun, this.frmBulanan.bulan);
	},
	methods: {
		async getTbl1() {
			const { data } = await this.$http.get("dashboard");
			this.tbl1 = data.data;
		},
		getPerhari: debounce(async function (tahun, bulan) {
			this.tblBulan = [];
			const { data } = await this.$http.get("report/perhari", {
				params: { tahun, bulan },
			});
			this.tblBulan = data.data;
			this.setTotalBulanan("totalBulanan", "tblBulan");
		}, 700),
		getPerbulan: debounce(async function (tahun) {
			this.tblTahun = [];
			this.chart.loaded = false;
			const { data } = await this.$http.get("report/perbulan", {
				params: { tahun },
			});
			this.tblTahun = data.data;
			this.setTotalBulanan("totalTahunan", "tblTahun");
			this.setChart();
			this.chart.loaded = true;
			//this.setTotalBulanan();
		}, 700),

		setTotalBulanan(key, key2) {
			this[key] = {
				jumlahOrder: 0,
				jumlahItem: 0,
				totalBayar: 0,
				totalHargaBeli: 0,
				jumlahReturn: 0,
				jumlahCancel: 0,
			};
			for (const i in this[key2]) {
				const el = this[key2][i];
				this[key].jumlahOrder += el.jumlahOrder;
				this[key].jumlahItem += el.jumlahItem;
				this[key].totalBayar += el.totalBayar;
				this[key].totalHargaBeli += el.totalHargaBeli;
				this[key].jumlahReturn += el.jumlahReturn;
				this[key].jumlahCancel += el.jumlahCancel;
			}
		},

		setChart() {
			let label = [];
			for (const key in this.bulan) {
				if (Object.hasOwnProperty.call(this.bulan, key)) {
					const el = this.bulan[key];
					label.push(el);
				}
			}

			let dataOrder = [];
			let dataItem = [];
			let dataOmset = [];

			for (const key in this.tblTahun) {
				if (Object.hasOwnProperty.call(this.tblTahun, key)) {
					const el = this.tblTahun[key];
					dataOrder.push(el.jumlahOrder);
					dataItem.push(el.jumlahItem);
					if (guard("harga_beli")) dataOmset.push(el.totalBayar / 100000);
				}
			}

			this.chart.data = {
				labels: label,
				datasets: [
					{
						label: "ORDER",
						backgroundColor: "#1a60ed",
						borderColor: "#1a60ed",
						data: dataOrder,
						fill: false,
					},
					{
						label: "ITEM",
						backgroundColor: "#ed1aa0",
						borderColor: "#ed1aa0",
						data: dataItem,
						fill: false,
					},
					{
						label: "OMSET",
						backgroundColor: "#1dc418",
						borderColor: "#1dc418",
						data: dataOmset,
						fill: false,
					},
				],
			};

			//console.log(this.chart);
		},
	},
};
</script>
